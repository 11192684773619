import { Chain } from '@usedapp/core';

export const Filecoin: Chain = {
  chainId: 314,
  chainName: 'Filecoin',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xffd927d6F17495B28635DD49d24638e97BD8c8b8',
  getExplorerAddressLink: (address: string) => `https://filecoin.blockscout.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://filecoin.blockscout.com/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://filecoin.blockscout.com',
  nativeCurrency: {
    name: 'Filecoin',
    symbol: 'FIL',
    decimals: 18,
  },
};

export const PolygonZkEVM: Chain = {
  chainId: 1101,
  chainName: 'Polygon zkEVM',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x4dd2886836eB5966dd2F5a223182E7889CD7F8a6',
  getExplorerAddressLink: (address: string) => `https://zkevm.polygonscan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://zkevm.polygonscan.com/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://zkevm.polygonscan.com',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Rootstock: Chain = {
  chainId: 30,
  chainName: 'Rootstock',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) =>
    `https://rootstock.blockscout.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://rootstock.blockscout.com/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://rootstock.blockscout.com',
  nativeCurrency: {
    name: 'Rootstock Smart Bitcoin',
    symbol: 'RBTC',
    decimals: 18,
  },
};

export const Scroll: Chain = {
  chainId: 534352,
  chainName: 'Scroll',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://scrollscan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://scrollscan.com/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://scrollscan.com',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const ZkSync: Chain = {
  chainId: 324,
  chainName: 'ZkSync Era',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x77Fce0B11B6F342B5f7a68114A03cAbb808a77e5',
  getExplorerAddressLink: (address: string) => `https://explorer.zksync.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://explorer.zksync.io/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://explorer.zksync.io',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Metis: Chain = {
  chainId: 1088,
  chainName: 'Metis',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://explorer.metis.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://explorer.metis.io/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://explorer.metis.io',
  nativeCurrency: {
    name: 'MetisDAO',
    symbol: 'METIS',
    decimals: 18,
  },
};

export const MantaPacific: Chain = {
  chainId: 169,
  chainName: 'Manta Pacific',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) =>
    `https://pacific-explorer.manta.network/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://pacific-explorer.manta.network/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://pacific-explorer.manta.network',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Blast: Chain = {
  chainId: 81457,
  chainName: 'Blast',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://blastscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://blastscan.io/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://blastscan.io',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Mode: Chain = {
  chainId: 34443,
  chainName: 'Mode',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://explorer.mode.network/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://explorer.mode.network/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://explorer.mode.network',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Taiko: Chain = {
  chainId: 167000,
  chainName: 'Taiko',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://taikoscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://taikoscan.io/tx/${transactionHash}`,
  // Optional parameters:
  // rpcUrl: 'https://rpc.tutorialchain.io',
  blockExplorerUrl: 'https://taikoscan.io',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Hardhat: Chain = {
  chainId: 31337,
  chainName: 'Hardhat',
  isTestChain: true,
  isLocalChain: true,
  multicallAddress: '',
  getExplorerAddressLink: () => '',
  getExplorerTransactionLink: () => '',
  blockExplorerUrl: '',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const ArbitrumSepolia: Chain = {
  chainId: 421614,
  chainName: 'Arbitrum Sepolia',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://sepolia.arbiscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://sepolia.arbiscan.io/tx/${transactionHash}`,
  blockExplorerUrl: 'https://sepolia.arbiscan.io',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const Sei: Chain = {
  chainId: 1329,
  chainName: 'Sei',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://seitrace.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://seitrace.com/tx/${transactionHash}`,
  blockExplorerUrl: 'https://seitrace.com',
  nativeCurrency: {
    name: 'Sei',
    symbol: 'SEI',
    decimals: 18,
  },
};

export const Mantle: Chain = {
  chainId: 5000,
  chainName: 'Mantle',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://explorer.mantle.xyz/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://explorer.mantle.xyz/tx/${transactionHash}`,
  blockExplorerUrl: 'https://explorer.mantle.xyz',
  nativeCurrency: {
    name: 'Mantle',
    symbol: 'MNT',
    decimals: 18,
  },
};

export const Lisk: Chain = {
  chainId: 1135,
  chainName: 'Lisk',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '',
  getExplorerAddressLink: (address: string) => `https://blockscout.lisk.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://blockscout.lisk.com/tx/${transactionHash}`,
  blockExplorerUrl: 'https://blockscout.lisk.com',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
};
