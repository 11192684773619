// @mui
import { Card, CardProps, CardContent } from '@mui/material';
// components
import { ApexOptions } from 'apexcharts';
import { niceNumber2 } from 'src/utils/formatPrices';
import { DataPointsConfig } from 'src/adrastia.config';
import Chart, { useChart } from '../chart';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  series: {
    name: string;
    data: {
      x: number; // timestamp
      y: number; // price
    }[];
  }[];
  ratePrefix?: string;
  rateSuffix?: string;
  dataPointsConfig: DataPointsConfig;
}

export default function LiquidityHistoryChart({
  title,
  series,
  ratePrefix,
  rateSuffix,
  dataPointsConfig,
}: Props) {
  const yaxis = [];
  const yaxisDecoration = [];

  var min = Math.min.apply(
    null,
    series[0].data.map((d) => d.y).concat(series[1].data.map((d) => d.y))
  );
  var max = Math.max.apply(
    null,
    series[0].data.map((d) => d.y).concat(series[1].data.map((d) => d.y))
  );

  min -= Math.abs(min) * 0.01;
  max += Math.abs(max) * 0.01;

  yaxis.push({
    labels: {
      formatter: (value: number) => {
        return (ratePrefix ?? '') + niceNumber2(value, 6) + (rateSuffix ?? '');
      },
    },
    forceNiceScale: true,
    min: min,
    max: max,
  });

  yaxisDecoration.push({
    labels: {
      show: false,
    },
    forceNiceScale: true,
    min: min,
    max: max,
  });

  const downloadFileName = 'adrastia_' + title.replace(/\s/g, '_').toLowerCase();

  const chartOptions: ApexOptions = {
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        format: 'MMM dd, yy',
        showDuplicates: false,
      },
    },
    yaxis: yaxis[0],
    stroke: {
      curve: 'stepline',
    },
    markers: {
      size: 0,
    },
    tooltip: {
      x: {
        format: 'MMM dd, yy HH:mm',
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: yaxisDecoration[0],
        },
      },
    ],
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        export: {
          csv: {
            filename: downloadFileName,
            columnDelimiter: ',',
            headerCategory: 'Time',
            dateFormatter(timestamp: any) {
              return new Date(timestamp).toISOString();
            },
          },
          svg: {
            filename: downloadFileName,
          },
          png: {
            filename: downloadFileName,
          },
        },
      },
    },
    title: {
      text: title,
    },
  };

  const chart = useChart(chartOptions);

  return <Chart options={chart} series={series} type="line" height={512} />;
}
