// @mui
import { Card, CardProps, useTheme, CardContent } from '@mui/material';
// components
import { ApexOptions } from 'apexcharts';
import { niceNumber } from 'src/utils/formatPrices';
import { useMemo } from 'react';
import Chart, { useChart } from './chart';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  base: number;
  baseSlope: number;
  kinkSlope: number;
  kink: number;
  xLabelPrefix?: string;
  xLabelSuffix?: string;
  yLabelPrefix?: string;
  yLabelSuffix?: string;
  currentX?: number;
}

export default function KinkedSlopeChart({
  base,
  baseSlope,
  kinkSlope,
  kink,
  xLabelPrefix,
  xLabelSuffix,
  yLabelPrefix,
  yLabelSuffix,
  currentX,
}: Props) {
  const theme = useTheme();

  const tooltipStyle = {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
  };

  const series = useMemo(() => {
    const series1Data = [];

    for (var i = 0; i <= 1000; ++i) {
      const x = i / 10;

      const y = Math.max(base + baseSlope * x + Math.max(0, x - kink) * kinkSlope, 0);

      series1Data.push({
        x: x,
        y: y,
      });
    }

    return [
      {
        name: 'f(x)',
        data: series1Data,
      },
    ];
  }, [base, baseSlope, kinkSlope, kink]);

  const xAxisAnnotations: any[] = [];

  if (currentX != null) {
    xAxisAnnotations.push({
      x: currentX,
      label: {
        orientation: 'horizontal',
        text: 'Current',
        style: tooltipStyle,
      },
    });
  }

  const chartOptions: ApexOptions = {
    xaxis: {
      type: 'numeric',
      labels: {
        formatter: (value: string) => {
          return (xLabelPrefix ?? '') + niceNumber(Number(value), 2) + (xLabelSuffix ?? '');
        },
      },
      min: 0,
      max: 100,
    },
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return (yLabelPrefix ?? '') + niceNumber(value, 2) + (yLabelSuffix ?? '');
        },
      },
      min: 0,
    },
    stroke: {
      curve: 'straight',
    },
    markers: {
      size: 0,
    },
    tooltip: {
      x: {
        format: 'MMM dd, yy HH:mm',
      },
    },
    annotations: {
      xaxis: xAxisAnnotations,
    },
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'Time',
            dateFormatter(timestamp: any) {
              return new Date(timestamp).toISOString();
            },
          },
        },
      },
    },
    title: {
      text: 'Slope Chart',
    },
  };

  const chart = useChart(chartOptions);

  return (
    <Card>
      <CardContent>
        <Chart type="line" series={series} options={chart} height={300} />
      </CardContent>
    </Card>
  );
}
